import produce from 'immer/dist/immer';

const initialState = {
	error: null,

	// Valid reasons for remaking an order
	remakeReasons: null,
};

/**
 * This reducer is different than the other for reasons.
 */
export default (state = initialState, action) => {

	switch (action.type) {
		case 'LOAD_REMAKE_REASONS_RESOLVED':
			return produce(state, draft => {
				draft.remakeReasons = action.payload;
			});
	}

	return state;
};