/**
 * @param {Array} items
 * @param {String} key
 * @return {Object}
 */
export default (items, key = 'id') => {
	const output = {};
	items.forEach(item => {
		output[item[key]] = item;
	});

	return output;
};