import PropTypes from 'prop-types';
import connect from './connect';

import compileHandlebarsTranslations from '../../../utils/string/compileHandlebarsTranslations';

let cachedTranslations = {};
const idPrefix = 'myo.';

export const translate = (id, defaultMessage = '', variables = null) => {
	if (cachedTranslations[idPrefix + id]) {
		if (variables) {
			return compileHandlebarsTranslations(cachedTranslations[idPrefix + id], variables);
		}

		return cachedTranslations[idPrefix + id];
	}

	if (defaultMessage) {
		return defaultMessage;
	}

	return id;
};

const Translatable = ({id, translations, defaultMessage, variables}) => {
	cachedTranslations = translations;

	return translate(id, defaultMessage, variables);
};

Translatable.propTypes = {
	id: PropTypes.string.isRequired,
	defaultMessage: PropTypes.string,
	variables: PropTypes.object,
	translations: PropTypes.object.isRequired,
};

export default connect(Translatable);