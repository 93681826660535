import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../utils/classes/classNames';
import OrderShape from '../../data/shapes/Order';
import getRecipeName from '../../data/reducers/helpers/getRecipeName';
import Materials from '../../data/shapes/Materials';
import RemakeReason from '../../data/shapes/RemakeReason';
import Remake from '../../data/shapes/Remake';

import SVG from '../../shared/SVG/SVG';
import WarningModal from '../../shared/WarningModal/WarningModal';
import Button from '../../shared/Button/Button';
import ButtonList from '../../shared/ButtonList/ButtonList';

import WrapperPreview from './components/WrapperPreview/WrapperPreview';
import OrderData from './components/OrderData/OrderData';
import OrderActionButtons from './components/OrderActionButtons/OrderActionButtons';
import OrderList from './components/OrderList/OrderList';
import RemakesList from './components/RemakesList/RemakesList';

import close from './graphics/close.svg';

import connect from './connect';
import './order.scss';

class Order extends React.PureComponent {

	static propTypes = {
		className: PropTypes.string,
		isoLocale: PropTypes.string,
		searchMethod: PropTypes.string.isRequired,
		order: OrderShape.isRequired,
		materials: Materials.isRequired,
		remakes: PropTypes.arrayOf(Remake),
		actionError: PropTypes.string,
		otherOrders: PropTypes.arrayOf(OrderShape),
		remakeReasons: PropTypes.arrayOf(RemakeReason).isRequired,

		selectOrder: PropTypes.func.isRequired,
		closeOrder: PropTypes.func.isRequired,
		remake: PropTypes.func.isRequired,
		markAsPickedUp: PropTypes.func.isRequired,
		prioritize: PropTypes.func.isRequired,
		clearActionError: PropTypes.func.isRequired,
		loadRemakesOfOrder: PropTypes.func.isRequired,
	};

	componentDidMount() {
		this.props.loadRemakesOfOrder(this.props.order.id);
	}

	componentDidUpdate(prevProps) {
		if (this.props.order.id !== prevProps.order.id) {
			this.props.loadRemakesOfOrder(this.props.order.id);
		}
	}

	render() {
		const classes = classNames({
			'order': true,
			[this.props.className]: ! ! this.props.className,
		});

		const order = this.props.order;
		const materials = this.props.materials;
		const recipeName = getRecipeName(order.recipe, materials);

		return (
			<div className={classes}>
				<div className="order__background"/>

				<div className="order__content">
					<WrapperPreview
						className="order__wrapper"
						recipeName={recipeName}
						availableColors={materials.colors}
						wrapperConfiguration={order.wrapper}/>

					<OrderData
						className="order__order-data"
						order={order}
						recipeName={recipeName}
						isoLocale={this.props.isoLocale}/>

					<div className="order__actions">
						<OrderActionButtons
							className="order__buttons"
							order={order}
							remake={this.props.remake}
							remakeReasons={this.props.remakeReasons}
							markAsPickedUp={this.props.markAsPickedUp}
							prioritize={this.props.prioritize}/>

						{this.renderRemakesIfAny()}
					</div>
				</div>

				<button className="order__close" onClick={this.props.closeOrder}>
					<SVG src={close} className="order__close__icon"/>
				</button>

				{this.renderOtherOrders()}
				{this.renderActionErrorModal()}
			</div>
		);
	}

	renderOtherOrders() {
		if (! this.props.otherOrders || this.props.otherOrders.length === 0) {
			return null;
		}

		return (
			<div className="order__other-orders">
				<p>
					<span className="order__other-orders__title">Other orders </span>
					with the same {this.renderSearchMethodLabel()}
				</p>
				<OrderList orders={this.props.otherOrders} selectOrder={this.props.selectOrder}/>
			</div>
		);
	}

	renderSearchMethodLabel() {
		switch (this.props.searchMethod) {
			case 'email':
				return 'email address';

			case 'phone':
				return 'phone number';
		}

		return 'code';
	}

	renderActionErrorModal() {
		if (! this.props.actionError) {
			return null;
		}

		return (
			<WarningModal onCloseRequested={this.props.clearActionError}>
				<h2>Whoops</h2>
				{this.props.actionError}

				<ButtonList align="right">
					<Button color="white" onClick={this.props.clearActionError}>OK</Button>
				</ButtonList>
			</WarningModal>
		);
	}

	renderRemakesIfAny() {
		if (! this.props.remakes || this.props.remakes.length === 0) {
			return null;
		}

		return (
			<RemakesList remakes={this.props.remakes} className="order__remakes-list"/>
		);
	}
}

export default connect(Order);