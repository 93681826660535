import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';

import './styles/overall.scss';

import Raven from '../libraries/sentry';

import Translatable from './shared/Translatable/Translatable';
import Button from './shared/Button/Button';
import SVG from './shared/SVG/SVG';
import WarningModal from './shared/WarningModal/WarningModal';
import ButtonList from './shared/ButtonList/ButtonList';

import Login from './screens/Login/Login';
import Home from './screens/Home/Home';
import Order from './screens/Order/Order';
import Boot from './screens/Boot/Boot';

import ErrorWarning from './components/ErrorWarning/ErrorWarning';
import OfflineOverlay from './components/OfflineOverlay/OfflineOverlay';
import Logo from './components/Logo/Logo';

import logOutIcon from './graphics/logOut.svg';
import connect from './connect';
import './app.scss';

class App extends React.Component {

	static propTypes = {
		inDebugMode: PropTypes.bool,
		isLoadingData: PropTypes.bool,
		isOnline: PropTypes.bool,
		hasLoadedOrder: PropTypes.bool,

		locale: PropTypes.string,
		accessToken: PropTypes.string,
		errorMessage: PropTypes.string,
		raven: PropTypes.object.isRequired,

		loadRequiredData: PropTypes.func.isRequired,
		logOut: PropTypes.func.isRequired,
		getStoreStateWhenCrashed: PropTypes.func,
		updateMachineStatus: PropTypes.func.isRequired,
		loadStatistics: PropTypes.func.isRequired,
		loadRemakeReasons: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			showLogoutConfirmation: false,
		};
	}

	componentDidMount() {
		// Are we already logged in
		if (this.props.accessToken) {
			this.props.loadRequiredData();
			this.scheduleCronJobs();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errorMessage !== this.props.errorMessage) {
			this.setState({
				error: nextProps.errorMessage,
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.accessToken && ! prevProps.accessToken) {
			this.props.loadRequiredData();
			this.scheduleCronJobs();
		} else if (! this.props.accessToken && prevProps.accessToken) {
			this.stopCronJobs();
		}
	}

	componentWillUnmount() {
		this.stopCronJobs();
	}

	componentDidCatch(error, errorInfo) {
		this.setState({error: error});

		Raven.captureException(error, {
			extra: {
				errorInfo,
				state: this.props.getStoreStateWhenCrashed()
			}
		});
	}

	render() {
		return (
			<div className="app">

				{/* This is only needed to cache the translations, do not remove */}
				<div className="app__trans-cache">
					<Translatable id="toggle"/>
				</div>

				{this.state.error ? null : this.renderMainScreen()}

				<Logo className="app__logo"/>
				{this.renderLogout()}

				{this.renderErrorMessage()}
				{this.renderLogoutConfirmation()}
				<OfflineOverlay/>
			</div>
		);
	}

	renderMainScreen() {

		if (! this.props.accessToken) {
			return (<Login/>);
		}

		if (this.props.isLoadingData) {
			return (<Boot/>);
		}

		if (this.props.hasLoadedOrder) {
			return (<Order/>);
		}

		return (<Home/>);
	}

	renderErrorMessage() {
		if (! this.state.error || ! this.props.isOnline) {
			return null;
		}

		return (
			<ErrorWarning error={this.state.error}/>
		);
	}

	renderLogout() {
		if (! this.props.accessToken) {
			return null;
		}

		return (
			<Button
				size="small"
				color="blue"
				onClick={this.handleLogoutClicked}
				className="app__logout">
				<SVG src={logOutIcon} className="app__logout__icon"/>
			</Button>
		);
	}

	renderLogoutConfirmation() {
		if (! this.state.showLogoutConfirmation) {
			return;
		}

		return (
			<WarningModal onCloseRequested={this.cancelLogout}>
				<p>
					Are your sure you want to log out?
				</p>
				<ButtonList align="right">
					<Button onClick={this.cancelLogout}>Cancel</Button>
					<Button onClick={this.props.logOut} color="white">OK</Button>
				</ButtonList>
			</WarningModal>
		);
	}

	scheduleCronJobs() {
		this.stopCronJobs();

		/**
		 * Schedule a background job for updating the system status ever xx seconds
		 */
		this.intervals.push(setInterval(this.props.updateMachineStatus, 10 * 1000));
		this.intervals.push(setInterval(this.props.loadStatistics, 15 * 1000));
		this.intervals.push(setInterval(this.props.loadRemakeReasons, 10 * 60 * 1000));
	}

	stopCronJobs() {
		if (this.intervals) {
			this.intervals.forEach(id => clearInterval(id));
		}

		this.intervals = [];
	}

	cancelLogout = () => {
		this.setState({showLogoutConfirmation: false});
	}

	handleLogoutClicked = () => {
		this.setState({showLogoutConfirmation: true});
	}

}

export default hot(module)(connect(App));