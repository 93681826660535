import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const modalsContainer = document.querySelector('.modals__content');
const root = document.querySelector('.wrapper');
const content = document.querySelector('.app-container');

import './modal.scss';

class Modal extends React.Component {

	static propTypes = {
		children: PropTypes.node.isRequired,
	};

	componentDidMount() {
		this.lockRoot();
	}

	componentWillUnmount() {
		this.unlockRoot();
	}

	render() {
		return ReactDOM.createPortal(
			this.props.children,
			modalsContainer
		);
	}

	lockRoot() {
		this.scrollOffset = this.getScrollOffset();
		window.scrollTo(0, 0);

		content.style.transform = `translateY(${- this.scrollOffset}px)`;
		root.classList.add('MYO_wrapper--locked');
	}

	unlockRoot() {
		content.style.transform = 'none';
		root.classList.remove('MYO_wrapper--locked');

		window.scrollTo(0, this.scrollOffset);
	}

	getScrollOffset() {
		return window.pageYOffset || document.documentElement.scrollTop;
	}
}

export default Modal;