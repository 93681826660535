/* global OFFLINE_MODE */

import React from 'react';
import PropTypes from 'prop-types';
import jsQR from 'jsqr';

import classNames from '../../../../../../../utils/classes/classNames';
import SVG from '../../../../../../shared/SVG/SVG';

import crosshair from './graphics/crosshair.svg';
import './qr-scanner.scss';

class QRScanner extends React.PureComponent {

	static propTypes = {
		onScan: PropTypes.func,
		className: PropTypes.string,
	};

	constructor(props) {
		super(props);

		this.state = {
			bounds: null,
			id: null,
		};

		this.updateCanvasFromVideo = this.updateCanvasFromVideo.bind(this);
		this.findCodeInImage = this.findCodeInImage.bind(this);
	}

	componentDidMount() {
		if (OFFLINE_MODE) {
			console.warn('QR Scanner not running because we\'re in offline mode');
			return;
		}

		navigator.mediaDevices.getUserMedia({
			audio: false,
			video: {facingMode: 'environment'}
		})
			.then(stream => {
				this.video.srcObject = stream;

				this.updateCanvasFromVideo();
				this.findCodeInImage();
			})
			.catch(err => {
				alert(err.message);
			});
	}

	componentWillUnmount() {
		clearTimeout(this.scanTimeout);
	}

	render() {
		const crosshairClasses = classNames({
			'qr-scanner__crosshair': true,
			'qr-scanner__crosshair--found': ! ! this.state.id,
		});

		return (
			<div className={classNames('qr-scanner', this.props.className)}>
				<video className="qr-scanner__video" ref={el => this.video = el} autoPlay={true} playsInline={true}/>
				<canvas className="qr-scanner__canvas" ref={el => {
					this.canvas = el;
					this.context2d = el ? el.getContext('2d') : null;
				}}/>
				<SVG src={crosshair} className={crosshairClasses}/>
			</div>
		);
	}

	updateCanvasFromVideo() {
		if (! this.context2d) {
			return;
		}

		// Copy the video frame to canvas
		if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
			this.canvas.width = this.video.videoWidth;
			this.canvas.height = this.video.videoHeight;
			this.context2d.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight);
		}

		// Draw a code highlight box
		// const codeBounds = this.state.bounds;
		// if (codeBounds) {
		// 	this.drawLine(codeBounds.topLeftCorner, codeBounds.topRightCorner);
		// 	this.drawLine(codeBounds.topRightCorner, codeBounds.bottomRightCorner);
		// 	this.drawLine(codeBounds.bottomRightCorner, codeBounds.bottomLeftCorner);
		// 	this.drawLine(codeBounds.bottomLeftCorner, codeBounds.topLeftCorner);
		// }

		requestAnimationFrame(this.updateCanvasFromVideo);
	}

	findCodeInImage() {
		if (! this.context2d) {
			return;
		}

		if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
			const imageData = this.context2d.getImageData(0, 0, this.video.videoWidth, this.video.videoHeight);
			const scannedCode = jsQR(imageData.data, imageData.width, imageData.height);
			if (scannedCode) {
				const id = this.getIdFromURL(scannedCode.data);
				if (! id) {
					this.scheduleFindImage();
					return;
				}

				this.setState({
					id: scannedCode.data,
					bounds: scannedCode.location,
				});

				if (this.state.id !== id) {
					this.props.onScan(id);
				}
			}
		}

		this.scheduleFindImage();
	}

	scheduleFindImage() {
		this.scanTimeout = setTimeout(this.findCodeInImage, 100);
	}

	getIdFromURL(url) {
		const uuidInURL = url.match(/:\/\/[^\/]+\/([a-zA-Z0-9.]+)/);
		if (! uuidInURL) {
			console.warn(`"${url}" is not a potentially valid MYO URL.`);
			return false;
		}

		return uuidInURL['1'];
	}

	// drawLine(begin, end) {
	// 	this.context2d.beginPath();
	// 	this.context2d.moveTo(begin.x, begin.y);
	// 	this.context2d.lineTo(end.x, end.y);
	// 	this.context2d.lineWidth = 3;
	// 	this.context2d.strokeStyle = '#FF3B58';
	// 	this.context2d.stroke();
	// }
}

export default QRScanner;