import fetch from '../requests/authedFetch';

export default function loadTranslations(locale) {
	return {
		type: 'LOAD_TRANSLATIONS',
		meta: {
			locale: locale,
		},
		payload: fetch('GET', '/makeyourown/translations/myo', {locale: locale}).then(response => {
			if (Array.isArray(response.translations)) {
				response.translations = {};
			}

			return response;
		}),
	};
};