import React from 'react';

import classNames from '../../../utils/classes/classNames';

import LoadingIcon from '../../shared/LoadingIcon/LoadingIcon';

import './boot.scss';

const Boot = () => {
	return (
		<div className={classNames('boot')}>
			<LoadingIcon className={classNames('boot__icon')}/>
		</div>
	);
};

Boot.propTypes = {};

export default Boot;