import React from 'react';
import PropTypes from 'prop-types';

import Order from '../../../../data/shapes/Order';
import classNames from '../../../../../utils/classes/classNames';
import formatDateTimeForHumans from '../../../../../utils/date/formatDateTimeForHumans';
import SVG from '../../../../shared/SVG/SVG';

import OrderStatus from '../OrderStatus/OrderStatus';

import blue1 from './graphics/arrow-blue-1.svg';
import blue2 from './graphics/arrow-blue-2.svg';
import './order-list.scss';

class OrderList extends React.PureComponent {

	static propTypes = {
		orders: PropTypes.arrayOf(Order).isRequired,
		selectOrder: PropTypes.func.isRequired,
		className: PropTypes.string,
	};

	render() {
		return (
			<div className={classNames('order-list', this.props.className)}>
				{this.props.orders.map(this.renderOrder.bind(this))}
			</div>
		);
	}

	renderOrder(order, index = 0) {
		return (
			<a className="order-list__order" key={order.code} onClick={this.handleOrderClick.bind(this, order)}
				href="#">
				<SVG src={index % 2 === 1 ? blue1 : blue2} className="order-list__icon"/>
				<span className="order-list__content">
					<span className="order-list__code">{order.code}</span>
					{' - Ordered at '}
					{formatDateTimeForHumans(order.createdAt)}
					<OrderStatus className="order-list__order-status" status={order.status}/>
				</span>
			</a>
		);
	}

	handleOrderClick(order, event) {
		event.preventDefault();

		this.props.selectOrder(order);
	}
}

export default OrderList;