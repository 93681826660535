export default function getRecipeName(recipe, materials) {
	const parts = [];

	if (! recipe) {
		return '';
	}

	if (recipe.couvertureTopId) {
		parts.push(materials.couvertures[recipe.couvertureTopId].name);
	}

	if (recipe.couvertureBottomId && recipe.couvertureBottomId !== recipe.couvertureTopId) {
		parts.push(materials.couvertures[recipe.couvertureBottomId].name);
	}

	if (recipe.inclusions) {
		recipe.inclusions.forEach(inclusionId => {
			const inclusion = materials.inclusions[inclusionId];
			parts.push(inclusion.name);
		});
	}

	return parts.join(' ');
};