import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../../../utils/classes/classNames';

import SVG from '../../../SVG/SVG';

import './status-indicator-line.scss';

const getGraphic = require.context('./graphics', true, /\.svg$/);
const getIcon = (type) => getGraphic(`./${type}.svg`);

const renderNotes = notes => {
	if (! notes) {
		return null;
	}

	return (
		<div className={classNames('status-indicator-line__notes')}>
			{notes}
		</div>
	);
};

const StatusIndicatorLine = ({iconType, children, notes}) => {

	return (
		<div className={classNames('status-indicator-line')}>
			<SVG className={classNames('status-indicator-line__icon')} src={getIcon(iconType)}/>

			<div className={classNames('status-indicator-line__content')}>
				<div className={classNames('status-indicator-line__label')}>
					{children}
				</div>
				{renderNotes(notes)}
			</div>

		</div>
	);
};

StatusIndicatorLine.propTypes = {
	iconType: PropTypes.oneOf(['empty', 'warning', 'checkmark']),
	children: PropTypes.node.isRequired,
	notes: PropTypes.node,
};

StatusIndicatorLine.defaultProps = {
	iconType: 'empty',
};

export default StatusIndicatorLine;