import { combineReducers } from 'redux';
import deepmerge from 'deepmerge';

import i18n from './i18n';
import materials from './materials';
import orders from './orders';
import session from './session';
import system from './system';
import ui from './ui';

const reducers = combineReducers({i18n, materials, orders, session, system, ui});

export default (state, action) => {
	if (action.type === 'IMPORT_EXTERNAL_STATE') {
		state = deepmerge(state, action.state);
	}

	return reducers(state, action);
};