import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../utils/classes/classNames';

import './tabs.scss';

class Tabs extends React.Component {

	static propTypes = {
		className: PropTypes.string,
		tabs: PropTypes.object.isRequired,
		children: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			tabKey: Object.keys(props.tabs)[0],
		};
	}

	render() {
		return (
			<div className={classNames('tabs', this.props.className)}>
				<div className="tabs__list">
					{Object.keys(this.props.tabs).map(tabKey => {
						const classes = classNames({
							'tabs__tab': true,
							'tabs__tab--current': tabKey === this.state.tabKey,
						});

						return (
							<a href="#" key={tabKey} className={classes}
								onClick={this.handleTabClick.bind(this, tabKey)}>
								{this.props.tabs[tabKey]}
							</a>
						);
					})}
				</div>
				<div className="tabs__content">
					{this.props.children(this.state.tabKey)}
				</div>
			</div>
		);
	}

	handleTabClick(tabKey, event) {
		event.preventDefault();
		this.setState({tabKey});
	}
}

export default Tabs;