import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../../../utils/classes/classNames';

import './statistics.scss';

class Statistics extends React.PureComponent {

	static propTypes = {
		className: PropTypes.string,
		stats: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.number.isRequired,
			label: PropTypes.node.isRequired,
		})).isRequired,
	};

	render() {
		return (
			<div className={classNames('statistics', this.props.className)}>
				<div className="statistics__content">
					{this.props.stats.map((stat, index) => this.renderItem(stat.value, stat.label, index))}
				</div>
			</div>
		);
	}

	renderItem(value, label, key) {
		return (
			<div className="statistics__item" key={key}>
				<div className="statistics__value">{value}</div>
				<div className="statistics__label">{label}</div>
			</div>
		);
	}
}

export default Statistics;