import createReducer from './helpers/createReducer';
import expiresInToDate from './helpers/expiresInToDate';
import { settings as apiAuthentication } from '../requests/authedFetch';

const initialState = {
	isOnline: navigator.onLine,

	tokenType: null,
	accessToken: null,
	accessTokenValidThrough: null,
	refreshToken: null,

	loginErrors: null,
	isLoggingIn: false,
	loginFailed: false,
};

const setAuthentication = (draft, {tokenType, accessToken, refreshToken, accessTokenValidThrough}) => {
	draft.tokenType = tokenType;
	draft.accessToken = accessToken;
	draft.refreshToken = refreshToken;
	draft.accessTokenValidThrough = accessTokenValidThrough;

	localStorage.tokenType = tokenType ? tokenType : '';
	localStorage.accessToken = accessToken ? accessToken : '';
	localStorage.refreshToken = refreshToken ? refreshToken : '';
	localStorage.accessTokenValidThrough = accessTokenValidThrough ? accessTokenValidThrough.getTime() : '';

	apiAuthentication.tokenType = tokenType;
	apiAuthentication.accessToken = accessToken;
};

export default createReducer(initialState, {

	INIT_AUTHENTICATION: (draft) => {
		setAuthentication(draft, {
			tokenType: localStorage.tokenType,
			accessToken: localStorage.accessToken,
			accessTokenValidThrough: localStorage.accessTokenValidThrough && localStorage.accessTokenValidThrough !== '0' ? new Date(parseInt(localStorage.accessTokenValidThrough)) : null,
			refreshToken: localStorage.refreshToken,
		});
	},

	LOG_IN_PENDING: (draft) => {
		draft.loginErrors = null;
		draft.isLoggingIn = true;
		draft.loginFailed = false;
	},

	REFRESH_ACCESS_TOKEN_PENDING: (draft) => {
		draft.loginErrors = null;
		draft.isLoggingIn = true;
		draft.loginFailed = false;
	},

	LOG_IN_RESOLVED: (draft, action) => {
		draft.isLoggingIn = false;

		const response = action.payload;

		setAuthentication(draft, {
			tokenType: response.token_type,
			accessToken: response.access_token,
			refreshToken: response.refresh_token,
			accessTokenValidThrough: expiresInToDate(response.expires_in),
		});
	},

	REFRESH_ACCESS_TOKEN_RESOLVED: (draft, action) => {
		draft.isLoggingIn = false;

		const response = action.payload;

		setAuthentication(draft, {
			tokenType: response.token_type,
			accessToken: response.access_token,
			refreshToken: response.refresh_token,
			accessTokenValidThrough: expiresInToDate(response.expires_in),
		});
	},

	LOG_IN_REJECTED: (draft, action) => {
		draft.loginErrors = action.errors;
		draft.isLoggingIn = false;
		draft.loginFailed = true;
	},

	LOG_OUT: (draft) => {
		draft.loginFailed = false;

		setAuthentication(draft, {
			tokenType: null,
			accessToken: null,
			refreshToken: null,
			accessTokenValidThrough: null,
		});
	},

	SET_ONLINE_STATUS: (draft, action) => {
		draft.isOnline = action.isOnline;
	}
});