import React from 'react';
import PropTypes from 'prop-types';

import OrderStatusShape, {
	CANCELED,
	IN_MACHINE,
	PAID,
	PICKED_UP,
	READY_FOR_PICKUP,
	UNPAID
} from '../../../../data/shapes/OrderStatus';
import classNames from '../../../../../utils/classes/classNames';

import './order-status.scss';

const statusToLabel = (status) => {
	switch (status) {
		case UNPAID:
			return 'awaiting payment';
		case CANCELED:
			return 'order canceled';
		case PAID:
			return 'waiting for production';
		case IN_MACHINE:
			return 'now in production';
		case READY_FOR_PICKUP:
			return 'ready for pickup';
		case PICKED_UP:
			return 'picked up';
	}

	return `unkown status ${status}`;
};

const OrderStatus = ({status, className}) => {
	return (
		<div className={classNames('order-status', className)}>
			<span className={'order-status__light order-status__light--' + status}/>
			{statusToLabel(status)}
		</div>
	);
};

OrderStatus.propTypes = {
	status: OrderStatusShape.isRequired,
	className: PropTypes.string,
};

export default OrderStatus;