import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../../utils/classes/classNames';
import SVG from '../SVG/SVG';

import icon from './graphics/loading.svg';

import './loading-icon.scss';

const LoadingIcon = ({className}) => {
	const classes = classNames({
		'loading-icon': true,
		[className]: ! ! className,
	});

	return (
		<SVG src={icon} className={classes}/>
	);
};

LoadingIcon.propTypes = {
	className: PropTypes.string,
};

export default LoadingIcon;