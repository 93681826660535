import React from 'react';
import PropTypes from 'prop-types';

import Order from '../../../../../../data/shapes/Order';
import WarningModal from '../../../../../../shared/WarningModal/WarningModal';
import Select from '../../../../../../shared/Select/Select';
import RemakeReason from '../../../../../../data/shapes/RemakeReason';
import Button from '../../../../../../shared/Button/Button';
import ButtonList from '../../../../../../shared/ButtonList/ButtonList';
import generateArrayOfUndefined from '../../../../../../../utils/array/generateArrayOfUndefined';

import './remake-modal.scss';

class RemakeModal extends React.PureComponent {

	static propTypes = {
		order: Order.isRequired,
		onConfirm: PropTypes.func.isRequired,
		onCancel: PropTypes.func,
		reasons: PropTypes.arrayOf(RemakeReason).isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			reasonId: null,
			quantity: props.order.quantity === 1 ? 1 : null,
		};
	}

	render() {
		return (
			<WarningModal onCloseRequested={this.props.onCancel}>
				<div className="remake-modal">
					<h2 className="remake-modal__title">
						reorder
					</h2>
					<p>
						This order contains
						<span className="remake-modal__quantity"> {this.props.order.quantity} sets</span>.
					</p>

					{this.renderQuantitySelectIfMoreThanOne()}
					{this.renderRemakeReasonsSelect()}

					<ButtonList align="right">
						<Button
							disabled={! this.state.quantity || ! this.state.reasonId}
							color="red"
							onClick={this.handleConfirm}>
							reorder
						</Button>
					</ButtonList>
				</div>
			</WarningModal>
		);
	}

	renderQuantitySelectIfMoreThanOne() {
		if (this.props.order.quantity === 1) {
			return null;
		}

		const options = generateArrayOfUndefined(this.props.order.quantity).map((value, index) => ({
			label: index + 1,
			value: index + 1,
		}));

		return (
			<Select
				className="remake-modal__select"
				placeholder={'how many sets'}
				options={options}
				onChange={this.handleQuantityChange}/>
		);
	}

	renderRemakeReasonsSelect() {
		const options = this.props.reasons.map(reason => ({
			label: reason.title,
			value: reason.id,
		}));

		return (
			<Select
				className="remake-modal__select"
				placeholder={'choose reason'}
				options={options}
				onChange={this.handleReasonChange}/>
		);
	}

	handleReasonChange = (value) => {
		this.setState({reasonId: value});
	}

	handleQuantityChange = (value) => {
		this.setState({quantity: value});
	}

	handleConfirm = () => {
		this.props.onConfirm(this.state.reasonId, this.state.quantity);
	}
}

export default RemakeModal;