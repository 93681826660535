import React from 'react';
import PropTypes from 'prop-types';

import SVG from '../../../../../../shared/SVG/SVG';
import classNames from '../../../../../../../utils/classes/classNames';
import TextField from '../../../../../../shared/TextField/TextField';
import LoadingIcon from '../../../../../../shared/LoadingIcon/LoadingIcon';

import background1 from './graphics/arrow-1.svg';
import background2 from './graphics/arrow-2.svg';

const backgrounds = [background1, background2];
import './single-field-form.scss';

class SingleFieldForm extends React.PureComponent {

	static propTypes = {
		name: PropTypes.string.isRequired,
		className: PropTypes.string,
		disabled: PropTypes.bool,
		showLoading: PropTypes.bool,
		label: PropTypes.string,
		initialValue: PropTypes.string,
		variant: PropTypes.oneOf([1, 2]).isRequired,
		onSubmit: PropTypes.func,
		valueModifier: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			value: props.initialValue || '',
		};
	}

	render() {
		const classes = classNames({
			'single-field-form': true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<form className={classes} acceptCharset="utf-8" onSubmit={this.handleSubmit}>
				<TextField className="single-field-form__input"
					disabled={this.props.disabled}
					name={this.props.name}
					label={this.props.label}
					value={this.state.value}
					onChange={this.handleChange}/>

				<button type="submit" className="single-field-form__button" disabled={this.props.disabled}>
					<SVG src={backgrounds[this.props.variant - 1]}/>
				</button>

				{this.renderLoadingWhenIsLoading()}
			</form>
		);
	}

	renderLoadingWhenIsLoading() {
		if (! this.props.showLoading) {
			return null;
		}

		return (
			<LoadingIcon className="single-field-form__loading-icon"/>
		);
	}

	handleChange = (value) => {
		if (this.props.valueModifier) {
			value = this.props.valueModifier(value);
		}

		this.setState({value: value});
	}

	handleSubmit = (event) => {
		event.preventDefault();

		if (this.props.disabled) {
			return;
		}

		if (this.props.onSubmit) {
			this.props.onSubmit(this.state.value);
		}
	}
}

export default SingleFieldForm;