import createReducer from './helpers/createReducer';

const isoLocaleMap = {
	en: 'en-US',
};

const defaultLocale = 'en';

const initialState = {
	translations: {},
	locale: defaultLocale,
	isoLocale: isoLocaleMap[defaultLocale],
	validLocales: Object.keys(isoLocaleMap),
	isLoadingNewTranslations: false,
};

export default createReducer(initialState, {
	SET_LOCALE: (draft, action) => {
		const newLocale = action.locale;
		if (draft.validLocales.indexOf(newLocale) === - 1) {
			throw new Error('Cannot set locale "' + newLocale + '" as this is not a valid option.');
		}

		draft.locale = newLocale;
		draft.isoLocale = isoLocaleMap[newLocale];
	},
	LOAD_TRANSLATIONS_PENDING: (draft) => {
		draft.isLoadingNewTranslations = true;
	},
	LOAD_TRANSLATIONS_RESOLVED: (draft, action) => {
		const locale = action.meta.locale;
		draft.translations[locale] = action.payload.translations;
		draft.locale = locale;
		draft.isoLocale = isoLocaleMap[locale];
		draft.isLoadingNewTranslations = false;
	},
});