export default function repeatToLength(string, length) {
	if (! string || ! string.length) {
		return '';
	}

	while (string.length < length) {
		string = string + ' ' + string;
	}

	return string;
}