import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../../utils/classes/classNames';

import './text-input.scss';

class TextInput extends React.PureComponent {

	static propTypes = {
		name: PropTypes.string.isRequired,
		className: PropTypes.string,

		hasError: PropTypes.bool,
		type: PropTypes.string,
		disabled: PropTypes.bool,
		required: PropTypes.bool,
		autoComplete: PropTypes.bool,
		inputId: PropTypes.string,
		value: PropTypes.string,
		onChange: PropTypes.func,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
	};

	static defaultProps = {
		type: 'text',
	};

	render() {
		const classes = classNames({
			'text-input': true,
			['text-input--type-' + this.props.type]: true,
			'text-input--has-error': ! ! this.props.hasError,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<input className={classes}
				required={this.props.required}
				type={this.props.type}
				name={this.props.name}
				autoComplete={this.props.autoComplete ? undefined : 'off'}
				value={this.props.value}
				disabled={this.props.disabled}
				id={this.props.inputId}
				onFocus={this.props.onFocus}
				onBlur={this.props.onBlur}
				onChange={this.handleChange}/>
		);
	}

	handleChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange(event.target.value);
		}
	}
}

export default TextInput;