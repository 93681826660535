import { connect } from 'react-redux';

import logOut from './data/actions/logOut';
import loadRequiredData from './data/actions/loadRequiredData';
import loadRemakeReasons from './data/actions/loadRemakeReasons';
import updateMachineStatus from './data/actions/updateMachineStatus';
import loadStatistics from './data/actions/loadStatistics';

const mapStateToProps = state => {
	const hasTranslations = ! ! state.i18n.translations[state.i18n.locale];
	const hasMaterials = ! ! (state.materials[state.i18n.locale] && state.materials[state.i18n.locale].colors);
	const hasRemakeReasons = ! ! state.ui.remakeReasons;

	let accessToken = null;

	// Is the access token still valid
	if (state.session.accessTokenValidThrough) {
		if (state.session.accessTokenValidThrough.getTime() > (new Date()).getTime()) {
			accessToken = state.session.accessToken;
		}
	}

	return {
		isLoadingData: ! hasTranslations || ! hasMaterials || ! hasRemakeReasons,
		locale: state.i18n.locale,
		errorMessage: state.ui.error,
		isOnline: state.session.isOnline,
		hasLoadedOrder: ! ! state.orders.order,
		accessToken: accessToken,
	};
};

const mapDispatchToProps = (dispatch) => ({
	logOut: () => dispatch(logOut()),
	loadRequiredData: () => dispatch(loadRequiredData()),

	updateMachineStatus: () => dispatch(updateMachineStatus()),
	loadStatistics: () => dispatch(loadStatistics()),
	loadRemakeReasons: () => dispatch(loadRemakeReasons()),
});

export default connect(mapStateToProps, mapDispatchToProps);