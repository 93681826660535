import fetch from '../requests/authedFetch';
import loadRemakesOfOrder from './loadRemakesOfOrder';

export default function remakeOrder(orderId, reasonId, quantity) {
	return (dispatch) => {
		dispatch({
			type: 'REMAKE_ORDER',
			payload: fetch('POST', `/makeyourown/order/${orderId}/remake`, {
				reason: reasonId,
				quantity: quantity,
			}),
		}).then(() => {
			dispatch(loadRemakesOfOrder(orderId));
		});
	};
}