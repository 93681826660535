import fetch from '../requests/authedFetch';

export default function loadOrdersByPhoneNumber(phoneNumber) {

	// Prepend with 31 (for NL) if it starts with 0 except for 00
	if (! ! phoneNumber.match(/^0[^0].+/)) {
		phoneNumber = '31' + phoneNumber.substr(1);
	}

	return {
		type: 'LOAD_ORDERS_BY_PHONE_NUMBER',
		meta: {phoneNumber: phoneNumber},
		payload: fetch('POST', '/makeyourown/search', {
			type: 'phone',
			search: phoneNumber,
		}),
	};
}