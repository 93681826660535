import fetch from '../requests/authedFetch';

export default function loadMaterials(locale) {
	return {
		type: 'LOAD_MATERIALS',
		meta: {
			locale: locale,
		},
		payload: fetch('GET', '/makeyourown/materials', {
			locale: locale,
		}),
	};
};