/* global OFFLINE_MODE, API_DOMAIN */

import request from 'superagent';

export let API_SUFFIX = '';
export let DOMAIN = API_DOMAIN;

if (OFFLINE_MODE) {
	DOMAIN = '';
	API_SUFFIX = '.json';
}

/**
 * @param {String} method
 * @param {String} path
 * @param {Object} [parameters]
 * @param {Object} [headers]
 * @param {Object} [timeouts]
 * @returns {Promise}
 */
export default (method, path, parameters = null, headers = null, timeouts = null) => new Promise((resolve, reject) => {

	const apiCall = request(method, DOMAIN + path + API_SUFFIX)
		.set('Accept', 'application/json');

	if (headers) {
		Object.keys(headers).forEach(headerName => apiCall.set(headerName, headers[headerName]));
	}

	if (parameters) {
		method === 'GET' ? apiCall.query(parameters) : apiCall.send(parameters);
	}

	if (timeouts) {
		apiCall.timeout(timeouts);
	}

	return apiCall.end((error, result) => {
		if (error) {
			reject({error, response: result ? result.body : null});
			return;
		}

		resolve(result.body);
	});
});