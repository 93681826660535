import React from 'react';
import PropTypes from 'prop-types';

import WarningModal from '../../shared/WarningModal/WarningModal';
import Translatable from '../../shared/Translatable/Translatable';
import SVG from '../../shared/SVG/SVG';

import './error-warning.scss';

const errorToString = error => {
	return error && error.toString ? error.toString() : 'Unknown error';
};

import warningSVG from './graphics/warning.svg';

const ErrorWarning = ({error}) => {

	const errorString = errorToString(error);

	return (
		<WarningModal hideCloseX={true}>
			<div className="error-warning">
				<h2>
					<Translatable id="errorWarning.title" defaultMessage="whoopsy"/>
				</h2>

				<div className="error-warning__content">
					<SVG className="error-warning__icon" src={warningSVG}/>
					<p className="error-warning__body">
						<Translatable id="errorWarning.body"
							defaultMessage="This is embarrassing, something went wrong and it's totally not your fault! We have been notified automatically and will try to fix it. In the meantime, you can reload and try again?"/>
					</p>
				</div>

				<p className="error-warning__message">
					<Translatable id="errorWarning.errorMessage"
						defaultMessage={`Error message: ${errorString}`}
						variables={{errorMessage: errorString}}/>
				</p>
			</div>
		</WarningModal>
	);
};

ErrorWarning.propTypes = {
	error: PropTypes.any,
};

export default ErrorWarning;