import fetch from './fetch';

export const settings = {
	accessToken: null,
	tokenType: null,
};

export default (method, path, parameters = null) => {
	const headers = {
		Authorization: settings.tokenType + ' ' + settings.accessToken,
	};

	return fetch(method, '/api' + path, parameters, headers);
};