import PropTypes from 'prop-types';

import Color from './Color';
import Couverture from './Couverture';
import Inclusion from './Inclusion';

export default PropTypes.shape({
	colors: PropTypes.objectOf(Color).isRequired,
	couvertures: PropTypes.objectOf(Couverture).isRequired,
	inclusions: PropTypes.objectOf(Inclusion).isRequired,
});