import React from 'react';
import PropTypes from 'prop-types';

import Tabs from '../../../../shared/Tabs/Tabs';
import LoadingIcon from '../../../../shared/LoadingIcon/LoadingIcon';

import QRScanner from './components/QRScanner/QRScanner';
import SingleFieldForm from './components/SingleFieldForm/SingleFieldForm';

import './order-search.scss';

class OrderSearch extends React.Component {

	static propTypes = {
		loadOrderById: PropTypes.func.isRequired,
		loadOrderByCode: PropTypes.func.isRequired,
		loadOrdersByPhoneNumber: PropTypes.func.isRequired,
		loadOrdersByEmailAddress: PropTypes.func.isRequired,

		isLoadingOrders: PropTypes.bool,
		noResults: PropTypes.bool,
		searchMethod: PropTypes.string,
	};

	render() {
		const tabs = {
			phone: 'phone',
			email: 'email',
		};

		return (
			<div className="order-search">
				<div className="order-search__left">
					<h2>Scan QR</h2>

					<div className="order-search__scanner-wrapper">
						<QRScanner className="order-search__scanner"
							onScan={this.loadOrderByQRValueIfPotentiallyValid}/>
						{this.renderLoadingIfSearchingById()}
					</div>
				</div>
				<div className="order-search__right">
					<h2>Search</h2>

					<SingleFieldForm variant={1}
						valueModifier={value => value.toUpperCase()}
						showLoading={this.props.isLoadingOrders && this.props.searchMethod === 'code'}
						disabled={this.props.isLoadingOrders}
						className="order-search__order-number"
						name="order_number"
						label="order number"
						onSubmit={this.props.loadOrderByCode}/>

					{this.renderNoResultsIfSearchMethodEquals('code')}

					<Tabs tabs={tabs} className="order-search__customer-search">
						{(currentTabKey) => (
							<SingleFieldForm variant={2}
								showLoading={this.props.isLoadingOrders && this.props.searchMethod === currentTabKey}
								disabled={this.props.isLoadingOrders}
								key={currentTabKey}
								className={`order-search__${currentTabKey}`}
								name={currentTabKey}
								label={this.getMethodLabelByMethod(currentTabKey)}
								onSubmit={currentTabKey === 'email' ? this.props.loadOrdersByEmailAddress : this.props.loadOrdersByPhoneNumber}/>
						)}
					</Tabs>

					{this.renderNoResultsIfSearchMethodEquals('phone')}
					{this.renderNoResultsIfSearchMethodEquals('email')}
				</div>
			</div>
		);
	}

	renderLoadingIfSearchingById() {
		if (! this.props.isLoadingOrders || this.props.searchMethod !== 'id') {
			return null;
		}

		return (
			<LoadingIcon className="order-search__qr-loading"/>
		);
	}

	renderNoResultsIfSearchMethodEquals(method) {
		if (! this.props.noResults || this.props.searchMethod !== method) {
			return null;
		}

		return (
			<p className="order-search__error">
				Could not find any orders with this {this.getMethodLabelByMethod(method)}.
			</p>
		);
	}

	getMethodLabelByMethod(method) {
		switch (method) {
			case 'code':
				return 'code';

			case 'phone':
				return 'phone number';

			case 'email':
				return 'email address';

			case 'id':
			default:
				return 'ID';
		}
	}

	loadOrderByQRValueIfPotentiallyValid = (id) => {
		if (this.props.isLoadingOrders) {
			return;
		}

		this.props.loadOrderById(id);
	}
}

export default OrderSearch;