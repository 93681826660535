import PropTypes from 'prop-types';

import Recipe from './Recipe';
import WrapperConfiguration from './WrapperConfiguration';
import Customer from './Customer';
import OrderStatus from './OrderStatus';
import OrderAgent from './OrderAgent';

export default PropTypes.shape({
	id: PropTypes.string.isRequired,
	recipe: Recipe.isRequired,
	wrapper: WrapperConfiguration.isRequired,
	customer: Customer.isRequired,
	quantity: PropTypes.number.isRequired,
	status: OrderStatus.isRequired,
	code: PropTypes.string,
	orderAgent: OrderAgent,

	quantity: PropTypes.number.isRequired,
	paymentMethodId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	locale: PropTypes.string.isRequired,
	createdAt: PropTypes.instanceOf(Date).isRequired,
	readyForPickupAtEstimation: PropTypes.instanceOf(Date),
});