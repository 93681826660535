import { createStore, compose, applyMiddleware } from 'redux';

// import logger from './middleware/logger';
import crashReporter from './middleware/crashReporter';
import promise from './middleware/promise';
import thunk from './middleware/thunk';
import rejectedPromiseReporter from './middleware/rejectedPromiseReporter';
import unauthorizedRequest from './middleware/unauthorizedRequest';

import reducer from '../reducers/root';

/**
 * All middleware of the store
 */
const middleware = [
	thunk,
	promise,
	unauthorizedRequest,
	rejectedPromiseReporter,
	// logger,
	crashReporter,
];

/**
 * Compose the enhancers with one of the two
 */
let composeEnhancers = compose;
if (typeof window === 'object' && ! ! window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const enhancer = composeEnhancers(applyMiddleware(...middleware), /** Next enhancer here **/);

/**
 * Return a closure to create the store with an initial state
 * @param initialState
 * @return {Store<any>}
 */
export default initialState => createStore(reducer, initialState, enhancer);