import loadTranslations from './loadTranslations';
import loadStatistics from './loadStatistics';
import loadMaterials from './loadMaterials';
import updateMachineStatus from './updateMachineStatus';
import loadRemakeReasons from './loadRemakeReasons';

export default function loadRequiredData() {
	return (dispatch, getState) => {
		const state = getState();
		dispatch(loadTranslations(state.i18n.locale));
		dispatch(loadMaterials(state.i18n.locale));
		dispatch(updateMachineStatus());
		dispatch(loadStatistics());
		dispatch(loadRemakeReasons());
	};
}