import Raven from '../../../../libraries/sentry';
import isFailedRequest from '../../reducers/helpers/isFailedRequest';

const HTTP_UNPROCESSABLE_ENTITY = 422;

export default store => next => action => {
	if (isFailedRequest(action.type) && action.payload.error.status !== HTTP_UNPROCESSABLE_ENTITY) {
		Raven.captureException(action.payload);
	}

	return next(action);
};