import createReducer from './helpers/createReducer';
import normalize from './helpers/normalize';

const initialState = {};

export default createReducer(initialState, {
	LOAD_MATERIALS_RESOLVED: (draft, action) => {
		draft[action.meta.locale] = {
			colors: normalize(action.payload.colors),
			couvertures: normalize(action.payload.couvertures),
			inclusions: normalize(action.payload.inclusions),
		};
	}
});