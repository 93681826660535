import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../utils/classes/classNames';
import formatTimeForHumans from '../../../utils/date/formatTimeForHumans';
import Order from '../../data/shapes/Order';
import { CANCELED, IN_MACHINE, PAID, PICKED_UP, READY_FOR_PICKUP, UNPAID } from '../../data/shapes/OrderStatus';

import StatusIndicatorLine from './components/StatusIndicatorLine/StatusIndicatorLine';

import connect from './connect.js';
import './status-indicator.scss';

class StatusIndicator extends React.PureComponent {

	static propTypes = {
		className: PropTypes.string,
		isoLocale: PropTypes.string.isRequired,
		takesLongerThanNormal: PropTypes.bool.isRequired,
		order: Order.isRequired,
	};

	render() {
		const classes = classNames({
			'status-indicator': true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<div className={classes}>
				{this.renderSingleLine()}
			</div>
		);
	};

	renderSingleLine() {
		return (
			<StatusIndicatorLine iconType={this.getIconType()}>
				{this.getMessageBasedOnStatus()}
			</StatusIndicatorLine>
		);
	}

	getMessageBasedOnStatus() {
		switch (this.props.order.status) {

			case UNPAID:
				return 'the order is not paid and will not be taken in production';

			case PAID:
				return `the order is waiting and will be ready around ${this.getETA()}`;

			case IN_MACHINE:
				return `the order is in the machine and will be ready around ${this.getETA()}`;

			case READY_FOR_PICKUP:
				return 'the order is ready for pickup';

			case PICKED_UP:
				return 'the order was picked up by the customer';

			case CANCELED:
				return 'the order has been canceled';
		}

		return `This order has an unknown status of "${this.props.order.status}".`;
	};

	getIconType() {
		if ([UNPAID, CANCELED].indexOf(this.props.order.status) >= 0) {
			return 'warning';
		}

		return 'checkmark';
	};

	getETA() {
		return formatTimeForHumans(this.props.order.readyForPickupAtEstimation, this.props.isoLocale);
	}

}

export default connect(StatusIndicator);