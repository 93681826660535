import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../../utils/classes/classNames';

import TextInput from '../TextInput/TextInput';

import './text-field.scss';

let fieldCount = 0;

class TextField extends React.PureComponent {

	static propTypes = {
		label: PropTypes.string,
		name: PropTypes.string.isRequired,
		className: PropTypes.string,

		error: PropTypes.string,
		type: PropTypes.string,
		autoComplete: PropTypes.bool,
		disabled: PropTypes.bool,
		labelAlwaysAbove: PropTypes.bool,
		labelInline: PropTypes.bool,
		large: PropTypes.bool,
		required: PropTypes.bool,
		inputId: PropTypes.string,
		value: PropTypes.string,
		onChange: PropTypes.func,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			hasFocus: false,
		};
	}

	componentWillMount() {
		fieldCount ++;
		this.uniqueId = 'text-input_' + fieldCount;
	}

	render() {
		const classes = classNames({
			'text-field': true,
			'text-field--not-empty': ! ! this.props.value,
			'text-field--has-error': ! ! this.props.error,
			'text-field--no-label': ! this.props.label,
			'text-field--focus': this.state.hasFocus,
			'text-field--label-always-above': this.props.labelAlwaysAbove,
			'text-field--label-inline': this.props.labelInline,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<div className={classes}>
				<label htmlFor={this.getInputId()} className="text-field__label">
					{this.props.label}
				</label>

				<TextInput name={this.props.name}
					className="text-field__input"
					disabled={this.props.disabled}
					hasError={! ! this.props.error}
					type={this.props.type}
					autoComplete={this.props.autoComplete}
					large={this.props.large}
					inputId={this.getInputId()}
					value={this.props.value}
					onChange={this.props.onChange}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}/>

				{this.renderErrorWhenSet()}
			</div>
		);
	}

	renderErrorWhenSet() {
		if (! this.props.error) {
			return null;
		}

		return (
			<div className="text-field__error">{this.props.error}</div>
		);
	}

	handleFocus = (event) => {
		this.setState({hasFocus: true});

		if (this.props.onFocus) {
			this.props.onFocus(event);
		}
	}

	handleBlur = (event) => {
		this.setState({hasFocus: false});

		if (this.props.onBlur) {
			this.props.onBlur(event);
		}
	}

	getInputId() {
		if (this.props.inputId) {
			return this.props.inputId;
		}

		return this.uniqueId;
	}
}

export default TextField;