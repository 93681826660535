import React from 'react';
import PropTypes from 'prop-types';

import SVG from '../../shared/SVG/SVG';
import Translatable from '../../shared/Translatable/Translatable';

import nointerwebsIcon from './graphics/nointerwebs.svg';

import connect from './connect';
import './offline-overlay.scss';

class OfflineOverlay extends React.PureComponent {

	static propTypes = {
		goOnline: PropTypes.func.isRequired,
		goOffline: PropTypes.func.isRequired,
		isOnline: PropTypes.bool.isRequired,
	};

	componentDidMount() {
		window.addEventListener('online', this.props.goOnline);
		window.addEventListener('offline', this.props.goOffline);
	}

	componentWillUnmount() {
		window.removeEventListener('online', this.props.goOnline);
		window.removeEventListener('offline', this.props.goOffline);
	}

	render() {
		if (this.props.isOnline) {
			return null;
		}

		return (
			<div className="offline-overlay">
				<div className="offline-overlay__content">
					<SVG className="offline-overlay__icon" src={nointerwebsIcon}/>

					<p>
						<Translatable id="offline" defaultMessage="no internet connection"/>
					</p>
				</div>
			</div>
		);
	}
}

export default connect(OfflineOverlay);