import fetch from '../requests/authedFetch';
import loadOrder from './loadOrder';

export default function markOrderAsPickedUp(orderId) {
	return (dispatch) => {
		return dispatch({
			type: 'MARK_ORDER_AS_PICKED_UP',
			payload: fetch('POST', `/makeyourown/order/${orderId}/picked_up`),
		}).then(() => {
			dispatch(loadOrder(orderId));
		});
	};
}