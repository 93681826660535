import { connect } from 'react-redux';

const mapStateToProps = state => {
	return {
		canTakeNewOrders: state.system.canTakeNewOrders,
		queueWaitingTime: state.system.queueWaitingTime,
		takesLongerThanNormal: state.system.takesLongerThanNormal,
	};
};

export default connect(mapStateToProps);