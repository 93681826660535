/**
 * This wrapper file exists to include GSAP plugins, but also to be ably to
 * easily switch between TweenLite and TweenMax.
 */

import TweenMax from 'gsap/TweenMax';

// This alias is useful so the IDE can autocomplete the path to this file
export const Tween = TweenMax;
export const Timeline = null;

export default {
	Tween: Tween,
	Timeline: Timeline,
};