import React from 'react';
import PropTypes from 'prop-types';

import Remake from '../../../../data/shapes/Remake';
import classNames from '../../../../../utils/classes/classNames';

import RemakeInfo from './components/RemakeInfo/RemakeInfo';

import './remakes-list.scss';

const RemakesList = ({remakes, className}) => {
	return (
		<div className={classNames('remakes-list', className)}>
			{remakes.map((remake, index) => {
				return (
					<RemakeInfo key={index} remake={remake} className="remakes-list__item"/>
				);
			})}
		</div>
	);
};

RemakesList.propTypes = {
	remakes: PropTypes.arrayOf(Remake),
	className: PropTypes.string,
};

export default RemakesList;