import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../utils/classes/classNames';

import SVG from '../../shared/SVG/SVG';

import createYourOwn from './graphics/createyourown.svg';
import './logo.scss';

const Logo = ({className}) => {
	return (
		<div className={classNames('logo', className)}>
			<SVG src={createYourOwn} className="logo__svg"/>
		</div>
	);
};

Logo.propTypes = {
	className: PropTypes.string,
};

export default Logo;