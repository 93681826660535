import React from 'react';
import PropTypes from 'prop-types';

import ButtonList from '../../shared/ButtonList/ButtonList';
import Button from '../../shared/Button/Button';
import TextField from '../../shared/TextField/TextField';

import DecorationInclusions from '../../shared/DecorationInclusions/DecorationInclusions';

import connect from './connect';
import './login.scss';

class Login extends React.PureComponent {

	static propTypes = {
		logIn: PropTypes.func.isRequired,
		errors: PropTypes.object,
		isLoggingIn: PropTypes.bool,
		loginFailed: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
		};
	}

	render() {
		const errors = this.props.errors || {};

		return (
			<div className="login">
				<form className="login__form" acceptCharset="utf-8" action="#" onSubmit={this.handleSubmit}>
					<h2>log in</h2>

					<div className="login__fields">
						<TextField
							disabled={this.props.isLoggingIn}
							error={errors.username}
							className="login__input"
							name="username"
							label="username"
							type="email"
							required={true}
							value={this.state.username}
							onChange={this.handleUsernameChange}/>
						<TextField
							disabled={this.props.isLoggingIn}
							error={errors.password}
							className="login__input"
							type="password"
							name="password"
							label="password"
							required={true}
							value={this.state.password}
							onChange={this.handlePasswordChange}/>
					</div>

					{this.renderLoginFailure()}

					<ButtonList align="right">
						<Button color="red" iconName="arrow-right" type="submit" disabled={this.props.isLoggingIn}>
							log in
						</Button>
					</ButtonList>
				</form>

				<DecorationInclusions className="login__decoration"/>
			</div>
		);
	}

	renderLoginFailure() {
		if (! this.props.loginFailed) {
			return null;
		}

		return (
			<div className="login__failed-message">
				Login attempt failed. Please check your username and password and try again.
			</div>
		);
	}

	handleSubmit = (event) => {
		event.preventDefault();

		if (this.props.isLoggingIn) {
			return;
		}

		this.props.logIn(this.state.username, this.state.password);
	}

	handleUsernameChange = (value) => {
		this.setState({username: value});
	}

	handlePasswordChange = (value) => {
		this.setState({password: value});
	}
}

export default connect(Login);