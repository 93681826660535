import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../utils/classes/classNames';

import './button-list.scss';
import getDataProps from '../../../utils/props/getDataProps';

class ButtonList extends React.Component {

	static propTypes = {
		children: PropTypes.node.isRequired,
		className: PropTypes.string,
		align: PropTypes.oneOf(['left', 'center', 'right']),
	};

	static defaultProps = {
		align: 'left',
	};

	render() {
		const dataProps = getDataProps(this.props);

		const classes = classNames({
			'button-list': true,
			'button-list--multiple-children': this.props.children.length > 1,
			['button-list--align-' + this.props.align]: true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<div className={classes} {...dataProps}>
				{this.props.children}
			</div>
		);
	}
}

export default ButtonList;