import produce from 'immer';

export default (initialState, handlers) => {

	/**
	 * Return the created reducer
	 */
	return (state = initialState, action) => {
		if (handlers.hasOwnProperty(action.type)) {
			const actionHandler = handlers[action.type];

			return produce(state, draft => actionHandler(draft, action, state));
		}

		return state;
	};
};