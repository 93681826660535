import importOrder from './helpers/importOrder';
import createReducer from './helpers/createReducer';

const initialState = {
	isLoadingOrders: false,
	noResults: false,

	loadErrors: null,
	searchMethod: '',

	order: null,
	otherOrders: [],
	remakes: [],

	actionError: null,
};

const setLoading = (draft, method) => {
	draft.searchMethod = method;
	draft.isLoadingOrders = true;
	draft.noResults = false;
};

const setLoadingErrors = (draft, action) => {
	draft.isLoadingOrders = false;
	draft.loadErrors = action.payload;
};

const setOrder = (draft, action) => {
	draft.order = importOrder(action.payload);
	draft.remakes = [];
	draft.isLoadingOrders = false;
};

const setOrderAndOtherOrders = (draft, action) => {
	const orders = action.payload.orders;

	if (orders.length === 0) {
		draft.noResults = true;
	}

	// Take the first order and use it as the current
	draft.order = importOrder(orders.shift());
	draft.otherOrders = orders.map(order => importOrder(order));
	draft.isLoadingOrders = false;
};

const clearActionError = (draft) => {
	draft.actionError = null;
};

const setActionError = (draft, action) => {
	const pl = action.payload || {};
	const response = pl.response || {};
	const error = response.error || 'Unknown error';
	draft.actionError = error;
};

export default createReducer(initialState, {
	IMPORT_EXTERNAL_STATE: (draft) => {
		let orders = draft.order ? [draft.order] : [];
		if (draft.otherOrders) {
			orders = orders.concat(draft.otherOrders);
		}

		orders.forEach(order => {
			order.readyForPickupAtEstimation = new Date(order.readyForPickupAtEstimation);
			order.createdAt = new Date(order.createdAt);
		});
	},
	CLOSE_ORDER: (draft) => Object.assign(draft, initialState),
	LOG_OUT: (draft) => Object.assign(draft, initialState),
	LOAD_ORDER_PENDING: (draft) => setLoading(draft, 'id'),
	LOAD_ORDER_BY_CODE_PENDING: (draft) => setLoading(draft, 'code'),
	LOAD_ORDERS_BY_PHONE_NUMBER_PENDING: (draft) => setLoading(draft, 'phone'),
	LOAD_ORDERS_BY_EMAIL_ADDRESS_PENDING: (draft) => setLoading(draft, 'email'),
	LOAD_ORDER_REJECTED: setLoadingErrors,
	LOAD_ORDER_BY_CODE_REJECTED: setLoadingErrors,
	LOAD_ORDERS_BY_PHONE_NUMBER_REJECTED: setLoadingErrors,
	LOAD_ORDERS_BY_EMAIL_ADDRESS_REJECTED: setLoadingErrors,
	LOAD_ORDER_RESOLVED: setOrder,
	LOAD_ORDER_BY_CODE_RESOLVED: setOrderAndOtherOrders,
	LOAD_ORDERS_BY_PHONE_NUMBER_RESOLVED: setOrderAndOtherOrders,
	LOAD_ORDERS_BY_EMAIL_ADDRESS_RESOLVED: setOrderAndOtherOrders,
	LOAD_ORDER_REMAKES_RESOLVED: (draft, action) => {
		if (action.meta.orderId !== draft.order.id) {
			console.warn('Received remakes for an order that is no longer selected');
			return;
		}

		draft.remakes = action.payload.map(remake => {
			remake.createdAt = new Date(remake.created_at);
			delete remake.created_at;

			return remake;
		});
	},
	MARK_ORDER_AS_PICKED_UP_RESOLVED: (draft) => Object.assign(draft, initialState),
	SELECT_ORDER: (draft, action) => {
		const otherOrders = [draft.order].concat(draft.otherOrders).filter(order => order.code !== action.order.code);

		draft.order = action.order;
		draft.otherOrders = otherOrders;
	},
	CLEAR_ORDER_ACTION_ERROR: clearActionError,
	MARK_ORDER_AS_PICKED_UP_PENDING: clearActionError,
	REMAKE_ORDER_PENDING: clearActionError,
	PRIORITIZE_ORDER_PENDING: clearActionError,
	MARK_ORDER_AS_PICKED_UP_REJECTED: setActionError,
	REMAKE_ORDER_REJECTED: setActionError,
	PRIORITIZE_ORDER_REJECTED: setActionError,
});