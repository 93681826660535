/**
 * Lets you dispatch a function instead of an action.
 * This function will receive `dispatch` and `start` as arguments.
 *
 * `dispatch` will return the return value of the dispatched function.
 */
const thunk = store => next => action => {
	if (typeof action === 'function') {
		return action(store.dispatch, store.getState);
	}

	return next(action);
};

export default thunk;