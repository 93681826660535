/* global OAUTH_FRONTEND_CLIENT_ID, OAUTH_FRONTEND_CLIENT_SECRET */

import fetch from '../requests/fetch';
import loadRequiredData from './loadRequiredData';

export default function logIn(username, password) {
	return (dispatch) => {
		dispatch({
			type: 'LOG_IN',
			meta: {username, password},
			payload: fetch('POST', '/oauth/token', {
				grant_type: 'password',
				client_id: OAUTH_FRONTEND_CLIENT_ID,
				client_secret: OAUTH_FRONTEND_CLIENT_SECRET,
				username: username,
				password: password,
				scope: 'core admin',
			}),
		}).then(() => {
			dispatch(loadRequiredData());
		});
	};
};