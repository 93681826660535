import PropTypes from 'prop-types';

export default PropTypes.shape({
	backgroundColorId: PropTypes.number,
	outlineColorId: PropTypes.number,
	logoColorId: PropTypes.number,
	ribbonColorId: PropTypes.number,
	ribbonTextColorId: PropTypes.number,
	textLeftColorId: PropTypes.number,
	textRightColorId: PropTypes.number,
	textLeft: PropTypes.string,
	textLeftFontSize: PropTypes.number,
	textRight: PropTypes.string,
	textRightFontSize: PropTypes.number,
});