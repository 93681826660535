import Raven from '../../../../libraries/sentry';

export default store => next => action => {
	try {
		return next(action);
	} catch (err) {
		Raven.captureException(err, {
			extra: {
				action,
				state: store.getState()
			}
		});

		throw err;
	}
};