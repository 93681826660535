import fetch from '../requests/authedFetch';

export default function loadOrder(orderId) {
	return {
		type: 'LOAD_ORDER',
		meta: {
			id: orderId,
		},
		payload: fetch('GET', '/makeyourown/order/' + orderId)
	};
};