import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../utils/classes/classNames';

import SVG from '../SVG/SVG';

import fruits from './graphics/fruitsalad.svg';
import './decoration-inclusions.scss';

const DecorationInclusions = ({className}) => {
	return (
		<div className={classNames('decoration-inclusions', className)}>
			<SVG src={fruits} className="decoration-inclusions__graphic"/>
		</div>
	);
};

DecorationInclusions.propTypes = {
	className: PropTypes.string,
};

export default DecorationInclusions;