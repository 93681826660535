/* global process, SENTRY_DSN */
import Sentry from 'raven-js';

let Raven = {
	captureException(error, data) {
		console.warn('[MYO] Sent an exception to Sentry', error, data);
	}
};

// On anything but dev, log to Sentry
if (process.env.NODE_ENV !== 'development') {
	Sentry
		.config(SENTRY_DSN)
		.install();

	Raven = Sentry;
}

export default Raven;