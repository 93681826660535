import PropTypes from 'prop-types';

export const CANCELED = 'canceled';
export const UNPAID = 'unpaid';
export const PAID = 'paid';
export const IN_MACHINE = 'in_machine';
export const READY_FOR_PICKUP = 'ready_for_pickup';
export const PICKED_UP = 'picked_up';

export const ALL = [CANCELED, UNPAID, PAID, IN_MACHINE, READY_FOR_PICKUP, PICKED_UP];

export default PropTypes.oneOf(ALL);

// 'CREATED' => 'created',
// 'CANCELLED' => 'cancelled',
// 'SEND' => 'sent',
// 'INVOICE-RECEIVED' => 'invoice_received',
// 'INVOICE-PAID' => 'invoice_paid',