import { connect } from 'react-redux';
import logIn from '../../data/actions/logIn';

const mapStateToProps = state => {
	return {
		errors: state.session.loginErrors,
		loginFailed: state.session.loginFailed,
		isLoggingIn: state.session.isLoggingIn,
	};
};

const mapDispatchToProps = (dispatch) => ({
	logIn: (username, password) => dispatch(logIn(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps);