import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../../../utils/classes/classNames';

import connect from './connect';
import './system-status.scss';

const getLedColor = (canTakeNewOrders, takesLongerThanNormal) => {
	if (! canTakeNewOrders) {
		return 'red';
	}

	if (takesLongerThanNormal) {
		return 'orange';
	}

	return 'green';
};

const getWaitingTimeMessage = (canTakeNewOrders, takesLongerThanNormal, queueWaitingTime) => {
	if (! canTakeNewOrders) {
		return 'waiting time unknown';
	}

	if (takesLongerThanNormal) {
		return 'estimated waiting time ' + queueWaitingTime + 'm';
	}

	return 'current waiting time ' + queueWaitingTime + 'm';
};

const SystemStatus = ({className, canTakeNewOrders, queueWaitingTime, takesLongerThanNormal}) => {
	const classes = classNames('system-status', className);
	const ledClasses = 'system-status__led system-status__led--' + getLedColor(canTakeNewOrders, takesLongerThanNormal);
	const message = getWaitingTimeMessage(canTakeNewOrders, takesLongerThanNormal, queueWaitingTime);

	return (
		<div className={classes}>
			<span className="system-status__label">status</span>
			<span className={ledClasses}/>
			<div className="system-status__message">{message}</div>
		</div>
	);
};

SystemStatus.propTypes = {
	className: PropTypes.string,
	canTakeNewOrders: PropTypes.bool.isRequired,
	queueWaitingTime: PropTypes.number.isRequired,
	takesLongerThanNormal: PropTypes.bool.isRequired,
};

export default connect(SystemStatus);