import { UNPAID } from '../../shapes/OrderStatus';

const setCouvertures = (draft, topId, bottomId) => {
	// We always need a complete recipe (couverture + inclusions), so we trick it
	if (! draft.recipe) {
		draft.recipe = {
			inclusions: [],
		};
	}

	draft.recipe.couvertureTopId = topId;
	draft.recipe.couvertureBottomId = bottomId;
};

const setInclusions = (draft, inclusionIds) => {
	// We always need a complete recipe (couverture + inclusions), so we trick it
	if (! draft.recipe) {
		draft.recipe = {
			couvertureTopId: null,
			couvertureBottomId: null,
		};
	}

	draft.wantsNoInclusions = inclusionIds.length === 0;
	draft.recipe.inclusions = inclusionIds;
};

const setWrapper = (draft, wrapperConfiguration) => {
	draft.wrapper = wrapperConfiguration;
};

const setCustomer = (draft, phone, email, skippedPhoneNumber, wantsNewsletter) => {

	const customer = {
		wantsNewsletter: ! ! (phone && email) || wantsNewsletter,
	};

	if (! skippedPhoneNumber) {
		customer.phoneNumber = phone;
		customer.originalPhoneNumber = phone;
	}

	if (email) {
		customer.emailAddress = email;
	}

	draft.customer = customer;
};

export default function importOrder(orderData) {
	if (! orderData) {
		return null;
	}

	const customer = orderData.order.customer;
	const order = {};

	// Basic order information
	order.id = orderData.uuid;
	order.quantity = orderData.order.quantity;
	order.code = orderData.order.code;
	order.paymentMethodId = orderData.order.paymentMethodId;
	order.orderAgent = orderData.order.orderAgent;
	order.locale = orderData.order.locale;
	order.createdAt = new Date(orderData.order.createdAt);

	setCouvertures(order, orderData.order.recipe.couvertureTopId, orderData.order.recipe.couvertureBottomId);
	setInclusions(order, orderData.order.recipe.inclusions);
	setWrapper(order, orderData.order.wrapper);
	setCustomer(order, customer.phoneNumber, customer.emailAddress, ! customer.phoneNumber, ! ! customer.wantsNewsletter);

	// Meta data
	order.readyForPickupAtEstimation = new Date(orderData.readyForPickupAtEstimation);
	order.status = orderData.status ? orderData.status : UNPAID;

	return order;
}