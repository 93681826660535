export default (snippet, variables = {}) => {
	return snippet.replace(/{[a-zA-Z0-9]+}/g, match => {
		const name = match.replace(/[{}]/g, '');

		if (! variables[name]) {
			return '[' + name + ']';
		}

		return variables[name];
	});
};