/* global OFFLINE_MODE */
import { connect } from 'react-redux';
import setOnlineStatus from '../../data/actions/setOnlineStatus';

const mapStateToProps = (state) => {
	return {
		isOnline: state.session.isOnline || OFFLINE_MODE,
	};
};

const mapDispatchToProps = (dispatch) => ({
	goOffline: () => dispatch(setOnlineStatus(false)),
	goOnline: () => dispatch(setOnlineStatus(true)),
});

export default connect(mapStateToProps, mapDispatchToProps);