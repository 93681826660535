import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../utils/classes/classNames';
import getDataProps from '../../../utils/props/getDataProps';

import ButtonList from '../ButtonList/ButtonList';
import SVG from '../SVG/SVG';

const icons = require.context('./graphics', false, /\.svg$/);

let fetchIconByName = function(name) {
	return icons('./' + name + '.svg');
};

import './button.scss';

class Button extends React.Component {

	static propTypes = {
		children: PropTypes.node.isRequired,
		type: PropTypes.oneOf(['anchor', 'button', 'submit', 'span']),
		url: PropTypes.string,
		buttonType: PropTypes.string,
		disabled: PropTypes.bool,
		target: PropTypes.string,
		color: PropTypes.oneOf(['transparent', 'blue', 'red', 'white']),
		className: PropTypes.string,
		spiritId: PropTypes.string,
		iconName: PropTypes.string,
		iconClassName: PropTypes.string,
		labelClassName: PropTypes.string,
		iconPosition: PropTypes.oneOf(['left', 'right']),
		align: PropTypes.oneOf(['left', 'center', 'right', 'fill']),
		size: PropTypes.oneOf(['small', 'default', 'large']),
		onClick: PropTypes.func,
	};

	static defaultProps = {
		type: 'button',
		color: 'blue',
		buttonType: 'button',
		url: '#',
		disabled: false,
		align: 'left',
		target: '_self',
		iconPosition: 'right',
	};

	constructor(props) {
		super(props);

		this.state = {
			clipIndex: Math.floor(Math.random() * 3),
		};
	}

	render() {
		const dataProps = getDataProps(this.props);

		if (this.props.align !== 'left') {
			return (
				<ButtonList align={this.props.align} {...dataProps}>
					{this.renderButton()}
				</ButtonList>
			);
		}

		return this.renderButton(dataProps);
	}

	renderButton(dataProps = {}) {
		const classes = classNames({
			'button': true,
			['button--clip-' + this.state.clipIndex]: true,
			'button--has-icon': ! ! this.props.iconName && this.props.iconPosition !== 'left',
			'button--has-icon-left': ! ! this.props.iconName && this.props.iconPosition === 'left',
			'button--has-icon-right': ! ! this.props.iconName && this.props.iconPosition === 'right',
			'button--disabled': this.props.disabled,
			['button--color-' + this.props.color]: ! ! this.props.color,
			['button--size-' + this.props.size]: ! ! this.props.size,
			[this.props.className]: ! ! this.props.className
		});

		switch (this.props.type) {
			case 'anchor':
				return (
					<a className={classes}
						key="a"
						data-spirit-id={this.props.spiritId}
						onClick={! this.props.disabled ? this.props.onClick : this.handleDisabledClick}
						href={this.props.url}
						target={this.props.target}
						{...dataProps}>

						{this.renderButtonContents()}
					</a>
				);

			case 'button':
			case 'submit':
				return (
					<button
						key="button"
						disabled={this.props.disabled}
						data-spirit-id={this.props.spiritId}
						className={classes}
						type={this.props.type}
						onClick={! this.props.disabled ? this.props.onClick : this.handleDisabledClick}
						{...dataProps}>

						{this.renderButtonContents()}
					</button>
				);

			case 'span':
				return (
					<span className={classes} {...dataProps} key="span" data-spirit-id={this.props.spiritId}>
						{this.renderButtonContents()}
					</span>
				);
		}

		return null;
	}

	renderButtonContents() {
		const labelClasses = classNames({
			'button__label': true,
			[this.props.labelClassName]: ! ! this.props.labelClassName,
		});

		return [
			this.renderIcon('left'),
			<span className={labelClasses} key="label">{this.props.children}</span>,
			this.renderIcon('right'),
		];
	}

	renderIcon(position) {
		if (! this.props.iconName) {
			return null;
		}

		if (this.props.iconPosition !== position) {
			return null;
		}

		const classes = classNames({
			'button__icon': true,
			[this.props.iconClassName]: ! ! this.props.iconClassName,
		});

		return (
			<SVG src={fetchIconByName(this.props.iconName)} className={classes} key="icon"/>
		);
	}

	handleDisabledClick = (event) => {
		event.preventDefault();
		event.stopPropagation();
	}
}

export default Button;