export default store => next => action => {

	if (action.payload && typeof action.payload === 'object' && typeof action.payload.then === 'function') {

		const actionType = action.type;
		action.payload.then(
			(response) => {
				store.dispatch({
					type: actionType + '_RESOLVED',
					payload: response,
					meta: action.meta,
				});
			},
			(errorResponse) => {
				store.dispatch({
					type: actionType + '_REJECTED',
					payload: errorResponse,
					meta: action.meta,
				});
			}
		);

		// Continue
		action.type += '_PENDING';
		next(action);

		return action.payload;
	}

	return next(action);
};