import React from 'react';
import PropTypes from 'prop-types';

import DecorationInclusions from '../../shared/DecorationInclusions/DecorationInclusions';

import OrderSearch from './components/OrderSearch/OrderSearch';
import Statistics from './components/Statistics/Statistics';
import SystemStatus from './components/SystemStatus/SystemStatus';

import connect from './connect';
import './home.scss';

class Home extends React.Component {

	static propTypes = {
		loadOrderById: PropTypes.func.isRequired,
		loadOrderByCode: PropTypes.func.isRequired,
		loadOrdersByPhoneNumber: PropTypes.func.isRequired,
		loadOrdersByEmailAddress: PropTypes.func.isRequired,

		isLoadingOrders: PropTypes.bool,
		noResults: PropTypes.bool,
		searchMethod: PropTypes.string,

		setsInQueue: PropTypes.number,
		setsInProduction: PropTypes.number,
		setsReady: PropTypes.number,
	};

	render() {
		return (
			<div className="home">
				<div className="home__content">
					<OrderSearch
						noResults={this.props.noResults}
						searchMethod={this.props.searchMethod}
						loadOrderById={this.props.loadOrderById}
						loadOrderByCode={this.props.loadOrderByCode}
						loadOrdersByPhoneNumber={this.props.loadOrdersByPhoneNumber}
						loadOrdersByEmailAddress={this.props.loadOrdersByEmailAddress}
						isLoadingOrders={this.props.isLoadingOrders}/>

					<DecorationInclusions className="home__decoration-inclusions"/>

					<SystemStatus className="home__system-status"/>
				</div>

				<Statistics className="home__statistics" stats={this.getStats()}/>

			</div>
		);
	}

	getStats() {
		return [
			{value: this.props.setsInQueue, label: 'sets waiting for production'},
			{value: this.props.setsInProduction, label: 'sets in production'},
			{value: this.props.setsReady, label: 'sets ready for pickup'},
		];
	}

}

export default connect(Home);