export default function formatTimeForHumans(date, isoLocale = 'en-US') {
	if (! date) {
		return '';
	}

	if (! date.toLocaleTimeString) {
		return 'not a date';
	}

	return date.toLocaleTimeString(isoLocale, {
		hour: '2-digit',
		minute: '2-digit',
	});
}
