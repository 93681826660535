import logOut from '../../actions/logOut';
import isFailedRequest from '../../reducers/helpers/isFailedRequest';

const HTTP_UNAUTHORIZED_CODE = 401;

export default store => next => action => {
	if (isFailedRequest(action.type) && action.payload.error.status === HTTP_UNAUTHORIZED_CODE) {
		store.dispatch(logOut());
		return;
	}

	return next(action);
};