import React from 'react';
import PropTypes from 'prop-types';

import isDescendantOf from '../../../utils/dom/isDescendantOf';
import classNames from '../../../utils/classes/classNames';
import { Tween } from '../../../libraries/gsap';
import SVG from '../SVG/SVG';

import Modal from '../Modal/Modal';

import closeIcon from './graphics/close.svg';
import './warning-modal.scss';

const ESCAPE_KEY_CODE = 27;

class WarningModal extends React.Component {

	static propTypes = {
		className: PropTypes.string,
		children: PropTypes.node.isRequired,
		hideCloseX: PropTypes.bool,
		// Both the escape key and the x
		onCloseRequested: PropTypes.func,
	};

	componentDidMount() {
		document.addEventListener('click', this.handleDocumentClicked);
		window.addEventListener('keydown', this.handleKeyPressed, true);

		Tween.from(this.window, 0.35, {scale: 0.5, opacity: 0, ease: Back.easeOut});
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleDocumentClicked);
		window.removeEventListener('keydown', this.handleKeyPressed, true);
		Tween.killTweensOf(this.window);
	}

	render() {
		const classes = classNames({
			'warning-modal': true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<Modal>
				<div className={classes} ref={el => this.el = el}>
					<div className="warning-modal__window" ref={el => this.window = el}>
						<div className="warning-modal__content">
							{this.props.children}
						</div>
						{this.renderX()}
					</div>
				</div>
			</Modal>
		);
	}

	renderX() {
		if (this.props.hideCloseX) {
			return null;
		}

		return (
			<button className="warning-modal__x" onClick={this.handleCloseClicked}>
				<SVG src={closeIcon} className="warning-modal__x__icon"/>
			</button>
		);
	}

	handleCloseClicked = () => {
		if (this.props.onCloseRequested) {
			this.props.onCloseRequested();
		}
	}

	handleDocumentClicked = (event) => {
		if (! this.props.onCloseRequested) {
			return;
		}
		if (event.target !== this.el && ! isDescendantOf(event.target, this.el)) {
			this.props.onCloseRequested();
		}
	}

	handleKeyPressed = (event) => {
		if (event.keyCode === ESCAPE_KEY_CODE && this.props.onCloseRequested) {
			this.props.onCloseRequested();
		}
	}
}

export default WarningModal;