/* global process */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';

import Raven from './libraries/sentry';

import parseQueryString from './utils/url/parseQueryString';

import createStore from './app/data/store/store';
import scheduleAccessTokenRefresh from './app/scheduleAccessTokenRefresh';

import App from './app/App';

import './fonts/fonts.css';

/**
 * Parse the input
 * @type {object}
 */
const query = parseQueryString(window.location.search);

/**
 * Allow debug mode when `debug` is present in the querystring
 * @type {boolean}
 */
const DEBUG_MODE = process.env.NODE_ENV !== 'production' && query.hasOwnProperty('debug');

/**
 * Create the store and potentially import a debug state
 * @type {Store<any>}
 */
const store = createStore({});
if (DEBUG_MODE) {
	const debugState = require('./debugState.json');
	store.dispatch({type: 'IMPORT_EXTERNAL_STATE', state: debugState});
}

/**
 *
 */
store.dispatch({type: 'INIT_AUTHENTICATION'});

// Try to refresh the access token when we no longer have a valid access token
scheduleAccessTokenRefresh(store);

/**
 * Render the app into the container
 */
const container = document.querySelector('.app-container');
ReactDOM.render((
	<StoreProvider store={store}>
		<App inDebugMode={DEBUG_MODE} raven={Raven} getStoreStateWhenCrashed={store.getState}/>
	</StoreProvider>
), container);