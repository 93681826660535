import fetch from '../requests/authedFetch';

export default function loadOrderByCode(code) {
	return {
		type: 'LOAD_ORDER_BY_CODE',
		meta: {code: code},
		payload: fetch('POST', '/makeyourown/search', {
			type: 'order_id',
			search: code,
		}),
	};
}