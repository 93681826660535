export default function formatDateTimeForHumans(date, isoLocale = 'en-US') {
	if (! date) {
		return '';
	}

	if (! date.toLocaleTimeString) {
		return 'not a date';
	}

	return date.toLocaleDateString(isoLocale, {
		day: '2-digit',
		month: 'long',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	});
};