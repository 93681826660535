import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../utils/classes/classNames';
import SVG from '../SVG/SVG';

import dropdownIcon from './graphics/down.svg';
import './select.scss';

const Select = ({className, options, value, placeholder, onChange}) => {
	const classes = classNames({
		'select': true,
		[className]: ! ! className,
	});

	const placeholderEl = placeholder ? (<option value="">{placeholder}</option>) : null;
	const handleChange = event => onChange ? onChange(event.target.value) : null;

	return (
		<div className={classes}>
			<select className={classNames('select__input')} value={value} onChange={handleChange}>
				{placeholderEl}
				{options.map(method => (<option value={method.value} key={method.value}>{method.label}</option>))}
			</select>

			<SVG src={dropdownIcon} className={classNames('select__icon')}/>
		</div>
	);
};

Select.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({value: PropTypes.any, label: PropTypes.node})).isRequired,
	className: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
};

export default Select;