import React from 'react';
import PropTypes from 'prop-types';

import Remake from '../../../../../../data/shapes/Remake';
import formatDateTimeForHumans from '../../../../../../../utils/date/formatDateTimeForHumans';
import SVG from '../../../../../../shared/SVG/SVG';
import classNames from '../../../../../../../utils/classes/classNames';

import warning from './graphics/warning.svg';
import './remake-info.scss';

const RemakeInfo = ({remake, className}) => {
	return (
		<div className={classNames('remake-info', className)}>
			<SVG src={warning} className="remake-info__icon"/>

			Reordered {remake.quantity} sets because of {remake.reason.title}.
			<div className="remake-info__date">
				{formatDateTimeForHumans(remake.createdAt)}
			</div>
		</div>
	);
};

RemakeInfo.propTypes = {
	className: PropTypes.string,
	remake: Remake.isRequired,
};

export default RemakeInfo;