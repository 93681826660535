import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../../../utils/classes/classNames';

import Color from '../../../../data/shapes/Color';
import WrapperConfiguration from '../../../../data/shapes/WrapperConfiguration';

import FrontalBar from './components/FrontalBar/FrontalBar';

import './wrapper-preview.scss';

class WrapperPreview extends React.PureComponent {

	static propTypes = {
		availableColors: PropTypes.objectOf(Color).isRequired,
		className: PropTypes.string,
		spiritId: PropTypes.string,
		animateOnChange: PropTypes.bool,
		inAnimationDelay: PropTypes.number,
		wrapperConfiguration: WrapperConfiguration.isRequired,
		recipeName: PropTypes.string,
	};

	static defaultProps = {
		type: 'frontal',
	};

	constructor(props) {
		super(props);

		this.timeouts = [];

		this.state = {
			justChanged: false,
		};
	}

	componentWillReceiveProps() {
		this.setState({justChanged: true});
		this.timeouts.push(setTimeout(() => {
			this.setState({justChanged: false});
		}, 150));
	}

	componentWillUnmount() {
		this.timeouts.forEach(clearTimeout);
	}

	render() {
		const classes = classNames({
			'wrapper-preview': true,
			'wrapper-preview--just-changed': this.props.animateOnChange && this.state.justChanged,
			[this.props.className]: ! ! this.props.className,
		});

		const w = this.props.wrapperConfiguration;
		const colors = this.props.availableColors;

		const backgroundColor = w.backgroundColorId ? colors[w.backgroundColorId].hex : '#7aafe4';
		const outlineColor = w.outlineColorId ? colors[w.outlineColorId].hex : '#ffffff';
		const logoColor = w.logoColorId ? colors[w.logoColorId].hex : '#7aafe4';
		const ribbonColor = w.ribbonColorId ? colors[w.ribbonColorId].hex : '#b2e4ff';
		const ribbonTextColor = w.ribbonTextColorId ? colors[w.ribbonTextColorId].hex : '#7aafe4';
		const textLeftColor = w.textLeftColorId ? colors[w.textLeftColorId].hex : '#ffffff';
		const textRightColor = w.textRightColorId ? colors[w.textRightColorId].hex : '#ffffff';

		return (
			<div className={classes} data-spirit-id={this.props.spiritId}>
				<FrontalBar
					recipeName={this.props.recipeName}
					backgroundColor={backgroundColor}
					outlineColor={outlineColor}
					logoColor={logoColor}
					ribbonColor={ribbonColor}
					ribbonTextColor={ribbonTextColor}
					textLeftColor={textLeftColor}
					textRightColor={textRightColor}
					textLeft={w.textLeft}
					textLeftFontSize={w.textLeftFontSize}
					textRight={w.textRight}
					textRightFontSize={w.textRightFontSize}/>
			</div>
		);
	}
}

export default WrapperPreview;