import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../utils/classes/classNames';

import './svg.scss';

const SVG = ({className, src, spiritId, overflowVisible}) => {
	const classes = classNames({
		'svg': true,
		'svg--overflow-visible': overflowVisible,
		[className]: ! ! className,
	});

	return (
		<span className={classes} dangerouslySetInnerHTML={{__html: src}} data-spirit-id={spiritId}/>
	);
};

SVG.propTypes = {
	src: PropTypes.string.isRequired,
	className: PropTypes.string,
	spiritId: PropTypes.string,
	overflowVisible: PropTypes.bool,
};

SVG.defaultProps = {
	overflowVisible: true,
};

export default SVG;