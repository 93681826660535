import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../../../utils/classes/classNames';
import StatusIndicator from '../../../../shared/StatusIndicator/StatusIndicator';
import SVG from '../../../../shared/SVG/SVG';
import formatDateTimeForHumans from '../../../../../utils/date/formatDateTimeForHumans';
import { IPAD_INSTORE } from '../../../../data/shapes/OrderAgent';
import Order from '../../../../data/shapes/Order';

import avatar from './graphics/avatar.svg';
import './order-data.scss';

class OrderData extends React.PureComponent {

	static propTypes = {
		order: Order.isRequired,
		className: PropTypes.string,
		recipeName: PropTypes.string,
		isoLocale: PropTypes.string,
	};

	render() {
		const order = this.props.order;

		return (
			<div className={classNames('order-data', this.props.className)}>
				<div className="order-data__code">{order.code}</div>
				{this.renderQuantity(order.quantity)}
				<div className="order-data__recipe-name">{this.props.recipeName}</div>

				<StatusIndicator className="order-data__status-indicator"
					mode="afterOrder"
					order={order}/>

				<div className="order-data__created-at">
					Ordered at
					{' ' + this.renderOrderCreatedTime() + ' '}
					{this.renderOrderAgent()}.
				</div>

				<div className="order-data__customer">
					<SVG src={avatar} className="order-data__customer__icon"/>
					<div className="order-data__customer__details">
						{this.renderCustomerDetails()}
					</div>
				</div>
			</div>
		);
	}

	renderQuantity(quantity) {
		const valueClasses = classNames({
			'order-data__quantity__value': true,
			'order-data__quantity__value--multiple': quantity > 1,
		});

		return (
			<div className="order-data__quantity">
				Quantity:
				{' '}
				<span className={valueClasses}>{quantity}</span>
				{' '}
				{quantity > 1 ? 'sets' : 'set'}
			</div>
		);
	}

	renderOrderCreatedTime() {
		if (! this.props.order.createdAt) {
			return 'unknown date';
		}

		return formatDateTimeForHumans(this.props.order.createdAt, this.props.isoLocale);
	}

	renderOrderAgent() {
		if (this.props.order.orderAgent == IPAD_INSTORE) {
			return 'at the Beurs van Berlage';
		}

		return 'on own device';
	}

	renderCustomerDetails() {
		const details = [];

		const customer = this.props.order.customer;
		if (customer.phoneNumber) {
			details.push((
				<div className="order-data__customer__detail" key="phone">
					{customer.phoneNumber}
				</div>
			));
		}

		if (customer.emailAddress) {
			details.push((
				<div className="order-data__customer__detail" key="email">
					{customer.emailAddress}
				</div>
			));
		}

		return details;
	}
}

export default OrderData;
