import { connect } from 'react-redux';

import closeOrder from '../../data/actions/closeOrder';
import selectOrder from '../../data/actions/selectOrder';
import remakeOrder from '../../data/actions/remakeOrder';
import clearOrderActionError from '../../data/actions/clearOrderActionError';
import prioritizeOrder from '../../data/actions/prioritizeOrder';
import markOrderAsPickedUp from '../../data/actions/markOrderAsPickedUp';
import loadRemakesOfOrder from '../../data/actions/loadRemakesOfOrder';

const mapStateToProps = state => {
	return {
		order: state.orders.order,
		otherOrders: state.orders.otherOrders,
		searchMethod: state.orders.searchMethod,
		actionError: state.orders.actionError,
		remakes: state.orders.remakes,
		remakeReasons: state.ui.remakeReasons,
		materials: state.materials[state.i18n.locale],
		isoLocale: state.materials[state.i18n.isoLocale],
	};
};

const mapDispatchToProps = (dispatch) => ({
	closeOrder: () => dispatch(closeOrder()),
	selectOrder: (order) => dispatch(selectOrder(order)),
	remake: (orderId, reasonId, quantity) => dispatch(remakeOrder(orderId, reasonId, quantity)),
	markAsPickedUp: (orderId) => dispatch(markOrderAsPickedUp(orderId)),
	prioritize: (orderId) => dispatch(prioritizeOrder(orderId)),
	clearActionError: () => dispatch(clearOrderActionError()),
	loadRemakesOfOrder: (orderId) => dispatch(loadRemakesOfOrder(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps);