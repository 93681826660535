import fetch from '../requests/authedFetch';

export default function updateMachineStatus() {
	return (dispatch, getState) => {
		const state = getState();

		if (! state.session.isOnline) {
			return;
		}

		dispatch({type: 'UPDATE_MACHINE_STATUS', payload: fetch('GET', '/makeyourown/machine-status')});
	};
};