import { connect } from 'react-redux';

import loadOrderByCode from '../../data/actions/loadOrderByCode';
import loadOrdersByPhoneNumber from '../../data/actions/loadOrdersByPhoneNumber';
import loadOrdersByEmailAddress from '../../data/actions/loadOrdersByEmailAddress';
import loadOrder from '../../data/actions/loadOrder';

const mapStateToProps = state => {
	return {
		isLoadingOrders: state.orders.isLoadingOrders,
		noResults: state.orders.noResults,
		searchMethod: state.orders.searchMethod,

		setsInQueue: state.system.setsInQueue,
		setsInProduction: state.system.setsInProduction,
		setsReady: state.system.setsReady,
	};
};

const mapDispatchToProps = (dispatch) => ({
	loadOrderById: (orderId) => dispatch(loadOrder(orderId)),
	loadOrderByCode: (code) => dispatch(loadOrderByCode(code)),
	loadOrdersByPhoneNumber: (phone) => dispatch(loadOrdersByPhoneNumber(phone)),
	loadOrdersByEmailAddress: (email) => dispatch(loadOrdersByEmailAddress(email)),
});

export default connect(mapStateToProps, mapDispatchToProps);