import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../shared/Button/Button';
import Order from '../../../../data/shapes/Order';
import classNames from '../../../../../utils/classes/classNames';
import WarningModal from '../../../../shared/WarningModal/WarningModal';
import ButtonList from '../../../../shared/ButtonList/ButtonList';
import RemakeReason from '../../../../data/shapes/RemakeReason';
import { PAID, READY_FOR_PICKUP, UNPAID } from '../../../../data/shapes/OrderStatus';

import RemakeModal from './components/RemakeModal/RemakeModal';

import './order-action-buttons.scss';

class OrderActionButtons extends React.PureComponent {

	static propTypes = {
		order: Order.isRequired,
		className: PropTypes.string,
		remakeReasons: PropTypes.arrayOf(RemakeReason).isRequired,

		remake: PropTypes.func.isRequired,
		markAsPickedUp: PropTypes.func.isRequired,
		prioritize: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			action: null,
		};
	}

	render() {
		return (
			<div className={classNames('order-action-buttons', this.props.className)}>
				<Button
					className="order-action-buttons__button"
					iconName="arrow-right"
					disabled={! this.canMarkOrderAsPickedUp()}
					onClick={() => this.askForConfirmationForAction(this.confirmMarkAsPickedUp)}
					key="pick-up">
					mark as picked up
				</Button>
				<Button
					className="order-action-buttons__button"
					iconName="arrow-right"
					size="small"
					disabled={! this.canRemakeOrder()}
					onClick={() => this.askForConfirmationForAction(this.confirmRemake)}
					key="remake">
					remake order
				</Button>

				{this.renderPrioritizeButtonIfAvailable()}

				{this.renderConfirmationDialogIfNeeded()}
			</div>
		);
	}

	renderPrioritizeButtonIfAvailable() {
		if (! this.canPrioritizeOrder()) {
			return null;
		}

		return (
			<Button
				className="order-action-buttons__button"
				iconName="arrow-right"
				size="small"
				onClick={() => this.askForConfirmationForAction(this.confirmPrioritize)}
				key="prioritize">
				prioritize
			</Button>
		);
	}

	renderConfirmationDialogIfNeeded() {
		if (! this.state.action) {
			return null;
		}

		if (this.state.action === this.confirmRemake) {
			return (
				<RemakeModal
					onConfirm={this.confirmRemake}
					onCancel={this.cancelAction}
					order={this.props.order}
					reasons={this.props.remakeReasons}/>
			);
		}

		return (
			<WarningModal className="order-action-buttons__confirm" onCloseRequested={this.cancelAction}>
				<p>
					{this.getConfirmationMessageId(this.state.action)}
				</p>

				<ButtonList align="right" className="order-action-buttons__confirm__buttons">
					<Button color="blue" onClick={this.cancelAction}>
						Cancel
					</Button>
					<Button color="white" onClick={this.confirmAction}>
						OK
					</Button>
				</ButtonList>
			</WarningModal>
		);
	}

	canRemakeOrder() {
		return [UNPAID, PAID].indexOf(this.props.order.status) === - 1;
	}

	canMarkOrderAsPickedUp() {
		return this.props.order.status === READY_FOR_PICKUP;
	}

	canPrioritizeOrder() {
		return this.props.order.status === PAID;
	}

	getConfirmationMessageId(action) {
		switch (action) {
			case this.confirmMarkAsPickedUp:
				return (
					<span>Are you sure you want to mark order {this.props.order.code} as picked up?</span>
				);
			case this.confirmPrioritize:
				return (
					<span>Are you sure you want to make order {this.props.order.code} a first priority?</span>
				);
			default:
				return (
					<span>Are you sure you want to do this?</span>
				);
		}
	}

	askForConfirmationForAction = (action) => {
		this.setState({action: action});
	}

	cancelAction = () => {
		this.setState({action: null});
	}

	confirmAction = () => {
		this.state.action();
		this.setState({action: null});
	}

	confirmRemake = (reasonId, quantity) => {
		this.cancelAction();
		this.props.remake(this.props.order.id, reasonId, quantity);
	}

	confirmMarkAsPickedUp = () => {
		this.cancelAction();
		this.props.markAsPickedUp(this.props.order.id);
	}

	confirmPrioritize = () => {
		this.cancelAction();
		this.props.prioritize(this.props.order.id);
	}
}

export default OrderActionButtons;