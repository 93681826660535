/* global OAUTH_FRONTEND_CLIENT_ID, OAUTH_FRONTEND_CLIENT_SECRET */

import fetch from '../requests/fetch';

export default function refreshAccessToken() {
	return (dispatch, getState) => {
		const refreshToken = getState().session.refreshToken;

		return dispatch({
			type: 'REFRESH_ACCESS_TOKEN',
			payload: fetch('POST', '/oauth/token', {
				grant_type: 'refresh_token',
				client_id: OAUTH_FRONTEND_CLIENT_ID,
				client_secret: OAUTH_FRONTEND_CLIENT_SECRET,
				refresh_token: refreshToken,
				scope: 'core admin',
			}),
		});
	};
};