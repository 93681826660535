import createReducer from './helpers/createReducer';

const initialState = {
	canTakeNewOrders: true,
	queueWaitingTime: 20,
	takesLongerThanNormal: false,

	// Statistics
	setsInQueue: 0,
	setsInProduction: 0,
	setsReady: 0,
};

export default createReducer(initialState, {
	UPDATE_MACHINE_STATUS_RESOLVED: (draft, action) => {
		const data = action.payload;

		draft.canTakeNewOrders = data.canTakeNewOrders;
		draft.queueWaitingTime = data.queueWaitingTime;
		draft.takesLongerThanNormal = data.takesLongerThanNormal;
	},

	LOAD_STATISTICS_RESOLVED: (draft, action) => {
		draft.setsInQueue = action.payload.in_production;
		draft.setsInProduction = action.payload.waiting_for_production;
		draft.setsReady = action.payload.ready_for_pickup;
	}
});