import refreshAccessToken from './data/actions/refreshAccessToken';

const scheduleAccessTokenRefresh = (store) => {
	const hasLoginData = store.getState().session.refreshToken;
	if (! hasLoginData) {

		// Check if we can refresh in 15 minutes
		setTimeout(() => scheduleAccessTokenRefresh(store), 15 * 60 * 1000);
		return;
	}

	store.dispatch(refreshAccessToken()).then((response) => {
		if (response && response.access_token) {
			const expiresInSeconds = parseInt(response.expires_in);

			// Schedule a refresh one minute before the key expires
			const refreshTimeout = (expiresInSeconds - 60) * 1000;
			setTimeout(() => scheduleAccessTokenRefresh(store), refreshTimeout);
		}
	});
};

export default scheduleAccessTokenRefresh;