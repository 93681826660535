import connectToRedux from '../../data/connectToRedux';

const getCurrentTranslations = state => {
	const translations = state.i18n.translations[state.i18n.locale];
	if (! translations) {
		return {};
	}

	return translations;
};

export default connectToRedux(state => ({
	translations: getCurrentTranslations(state),
}));