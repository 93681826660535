import fetch from '../requests/authedFetch';

export default function loadOrdersByEmailAddress(email) {
	return {
		type: 'LOAD_ORDERS_BY_EMAIL_ADDRESS',
		meta: {email: email},
		payload: fetch('POST', '/makeyourown/search', {
			type: 'email',
			search: email,
		}),
	};
}